(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductDialogCreateController', ProductDialogCreateController);

    ProductDialogCreateController.$inject = ['$timeout', '$scope', '$state', '$q', 'entity', 'Product', 'Category', 'ListService', 'UidService','$sce',
        'MessageService', 'Qinius', 'Property', 'ProductXmls', 'ProductXmlFileServes','$uibModal', 'Music', 'Mould','PlatformWechatSetting','Store',
        'RegionTemp','StoreAbutments','Principal', 'DiyEditorSetting', "TaskbillForms", 'DateUtils'];

    function ProductDialogCreateController($timeout, $scope, $state, $q, entity, Product, Category, ListService, UidService, $sce,
        MessageService, Qinius, Property, ProductXmls, ProductXmlFileServes,$uibModal, Music, Mould, PlatformWechatSetting, Store,
        RegionTemp, StoreAbutments,Principal, DiyEditorSetting, TaskbillForms, DateUtils) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_PDCREATE').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        localStorage.setItem('Production', 'edit');
        $scope.status = localStorage.getItem('Production');
        vm.product = entity;
        if (vm.product.minimumOrder == null || vm.product.minimumOrder == '') {
            vm.product.minimumOrder = 1
        }
        vm.clear = clear;
        vm.save = save;
        vm.search = search;
        vm.pTicketCheck = pTicketCheck;
        vm.saleableCheck = saleableCheck;
        vm.datePickerOpenStatus = {};
        vm.properties=[];
        vm.expandedProperties = [];
        vm.tokens = Qinius.get();
        $scope.colspanLen = 7; //产品行表格参数
        vm.xmlType='XML';
        vm.currentItemValueLine = {};
        vm.currentXmlLine = {};
        vm.domains = Qinius.getDomain();
        vm.openCalendar = openCalendar;

        vm.store = Store.getCurrent();

        vm.platformWechatSetting = PlatformWechatSetting.get();

        vm.propertyShow = false;
        vm.xmlShow = false;

        vm.categories =  Category.storeGetAllByCondition({
            items: [{key: "usable", op: "=", value: "true"}]
        });
        vm.diyEditorSettings = DiyEditorSetting.getAllByCondition({
            items: [{key: "cancelled", op: "=", value: "false"}]
        });

        vm.editorTypes = [{name:'万物编辑器',value:'POSTER'},{name:'冲印编辑器',value:'PICTURE'},{name:'卡片编辑器',value:'PICTURE_PRO'},
        {name:'相册编辑器',value:'NEW_EDITOR'},{name:'极简编辑器',value:'SIMPLEST'},{name:'模块化编辑器',value:'DIY_EDITOR'},
        {name:'表单编辑器',value:'FORM_EDITOR'}, {name:'动态台历编辑器',value:'CALENDAR_EDITOR'}];


        $scope.productType2Change = function(){
            if (vm.product.productType2 == 'BOOK') {
                vm.editorTypes = [{name:'相册编辑器',value:'NEW_EDITOR'}];
            }
            if (vm.product.productType2 == 'CALENDAR') {
                vm.editorTypes = [{name:'相册编辑器',value:'NEW_EDITOR'},{name:'动态台历编辑器',value:'CALENDAR_EDITOR'}];
            }
            if (vm.product.productType2 == 'PHOTO') {
                vm.editorTypes = [ {name:'冲印编辑器',value:'PICTURE'},{name:'卡片编辑器',value:'PICTURE_PRO'}];
            }
            if (vm.product.productType2 == 'THING') {
                vm.editorTypes = [{name:'万物编辑器',value:'POSTER'}, {name:'极简编辑器',value:'SIMPLEST'},
                {name:'模块化编辑器',value:'DIY_EDITOR'}, {name:'表单编辑器',value:'FORM_EDITOR'}];
            }
            if (vm.product.productType2 != 'BOOK') {
                vm.product.bindingType = 'Left';
            }
        }


        $scope.editorTypeChange = function(){
            if (vm.product.editorType !='BOOK_JUNIOR' && vm.product.editorType !='BOOK_SENIOR'
                && vm.product.editorType !='NEW_EDITOR') {
                vm.product.forceFill = false;
                vm.product.aiFill = false;
            }
        }
        $scope.aiFillChange = function(){
            if (vm.product.aiFill) {
                vm.product.forceFill = false;
            }
        }

        $scope.forceFillChange = function(){
            if (vm.product.forceFill) {
                vm.product.aiFill = false;
            }
        }

        if(!vm.product.id || vm.product.bindingType == null){
            vm.product.bindingType = 'Left';
        }

        if(!vm.product.id || vm.product.patternType == null){
            vm.product.patternType = 'BOOK';
        }

        $scope.deleteSupNumber = function(line){
            if (line == null) {
                return;
            }
            line.supNumber = null;
        }

        $scope.detailsTypeClick = function(item){
            vm.product.detailsType = item.value;
            angular.forEach(vm.detailsTypes,function (type) {
                if(type.value!=item.value){
                    type.selected = false;
                }
            })

            item.selected = true;
        };

        $scope.thirdUrlCreated = function () {
            vm.product.thirdUrl = vm.platformWechatSetting.storeNotifyUrl + "/#/" + vm.product.storeId + "/" + vm.product.id + "/product";
        }

        init()
        function init(){
            if(vm.product.itemValues){
                for(var i=0; i < vm.product.itemValues.length; i++){
                    for(var j=0; j<vm.product.itemValues[i].itemValueLines.length; j++){
                        if(!vm.product.itemValues[i].itemValueLines[j].sku){
                            vm.product.itemValues[i].itemValueLines[j].sku = vm.product.itemValues[i].sku
                        }
                        if(!vm.product.itemValues[i].itemValueLines[j].skuNumber){
                            vm.product.itemValues[i].itemValueLines[j].skuNumber = vm.product.itemValues[i].skuNumber
                        }
                    }
                }
            }
        }
        $scope.showTree = function(tree){
            tree.show = !tree.show;
        }

        $scope.productXmlLineCopy = function(itemValue, line){
            var productXmlLine = angular.copy(line);
            productXmlLine.added = true;
            productXmlLine.uuid= UidService.get();
            productXmlLine.id = null;
            itemValue.productXmlLines.push(productXmlLine);
        }

        $scope.updatePromotion = function (line) {
            createModal2('app/entities/product/product-promotion-dialog.html', 'StoreProductPromotionDialogController', line).result.then(
                function (data) {
                    line = data
                });
        }

        var createModal2 = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: item,
                }
            });
        };

        $scope.productXmlLineDelete = function(itemValue, line){
            angular.forEach(itemValue.productXmls, function (p) {
                if (p.id == line.productXmlId) {
                    p.select = false;
                    p.choose = false;
                }
            })

            if (!line.added) {
                line.deleted = true;
            } else {
                var ind = itemValue.productXmlLines.indexOf(line);
                if (ind >= 0) {
                    itemValue.productXmlLines.splice(ind, 1);
                }
            }
        }


        window.xmlLineProductFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id;
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get()+".pdf");
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                       for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.productXmlLines && item.productXmlLines.length>0) {
                                for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                                    var line = item.productXmlLines[j];
                                    if (line.uuid == uuid) {
                                        line.fileIdentifier =  $scope.domain + data.key;
                                        break;
                                    }
                                }
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.itemvalueDefault =  function(itemValue){
            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var value = vm.product.itemValues[i];
                if (value.uuid == itemValue.uuid) {
                    continue;
                }
                value.default = false;
            }
        }

        $scope.itemvalueLineDefault =  function(itemValue, line){
            for (var i = itemValue.itemValueLines.length - 1; i >= 0; i--) {
                var valueLine = itemValue.itemValueLines[i];
                if (line.uuid == valueLine.uuid) {
                    continue;
                }
                valueLine.default = false;
            }
        }

        $scope.amountChange = function (itemValue, amount) {
            if (itemValue == null || itemValue[amount] == null) {
                return;
            }
            if (!(/^-?\d+\.?\d{0,2}$/.test(itemValue[amount]))) {
                MessageService.error("金额只能输入小数点后两位数");
                itemValue[amount] = Math.round(itemValue[amount] * 100) / 100;
                return;
            }
        };

        $scope.productTypeChange = function () {
            if (vm.product.itemValues == null) {
                vm.product.itemValues = [];
            }
            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var itemValue = vm.product.itemValues[i];
                if (itemValue.added) {
                    vm.product.itemValues.splice(i, 1);
                } else {
                    itemValue.deleted = true;
                }
            }
        }

        $scope.deleteItemValueLine = function(itemValue, line){
            if (!line.added) {
                line.deleted = true;
            } else {
                var ind = itemValue.itemValueLines.indexOf(line);
                if (ind >= 0) {
                    itemValue.itemValueLines.splice(ind, 1);
                }
            }
        }

        $scope.addItemValueLine = function(item){
            if (item.itemValueLines == null) {
                item.itemValueLines = [];
            }
            if (vm.product.expandedObjectProperties == undefined) {
                MessageService.error("请选择规格");
                return;
            }
            var line = {added: true, uuid: UidService.get(), itemValueObjectValues: []};
            for (var i = 0; i < vm.product.expandedObjectProperties.length; i++) {
                var op = vm.product.expandedObjectProperties[i];
                var objectValue = {
                    seq: op.seq,
                    uuid: UidService.get(),
                    propertyValues: op.propertyValues,
                    propertyId: op.propertyId,
                    propertyName: op.propertyName
                };
                line.itemValueObjectValues.push(objectValue);
            }
            item.itemValueLines.push(line);
        }

        $scope.addItemValue = function () {
            if (vm.product.itemValues == null) {
                vm.product.itemValues = [];
            }
            if (vm.product.defaultObjectProperties == undefined) {
                MessageService.error("请选择规格");
                return;
            }
            var itemValue = {added: true, uuid: UidService.get(), objectValues: []};
            for (var i = 0; i < vm.product.defaultObjectProperties.length; i++) {
                var op = vm.product.defaultObjectProperties[i];
                var objectValue = {
                    seq: op.seq,
                    uuid: UidService.get(),
                    propertyValues: op.propertyValues,
                    propertyId: op.propertyId,
                    propertyName: op.propertyName
                };
                itemValue.objectValues.push(objectValue);
            }
            vm.product.itemValues.push(itemValue);
        }

        vm.music = Music.allByCondition({});

        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.product.$promise, vm.platformWechatSetting.$promise,  vm.categories.$promise, vm.store.$promise]).then(function () {
            RegionTemp.getAllByCondition({items: [{key: "storeId", value: vm.store.id, op: "="}]},function(res){
                vm.regionTemps = res;
                var exist = false;
                for (var i = 0; i < vm.regionTemps.length; i++) {
                    var item = vm.regionTemps[i];
                    if (item.id == vm.product.regionTempId) {
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    vm.product.regionTempId = null;
                }
            });
            vm.forms = TaskbillForms.findAll({ items:[{key: 'storeId', op: '=', value: vm.store.id}] });
            $scope.productType2Change();
            vm.moulds = Mould.getAllByCondition({
                items: [{key:"enable", op:"=", value:"true"}, {key: "storeId", op: "=", value: vm.store.id}]
            });

            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if (vm.product.itemValues && vm.product.itemValues.length>0) {
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var itemValue = vm.product.itemValues[i];
                    if (itemValue.cancelled) {
                        continue;
                    }
                    if (itemValue.productXmlLines && itemValue.productXmlLines.length > 0) {
                        itemValue.productXmlLines.sort(function(a, b){
                            return a.seq - b.seq;
                        })
                    }
                }
                $scope.selectItemValue = vm.product.itemValues[0];
            }
            if (vm.product.itemValues) {
                for (var i = 0; i < vm.product.itemValues.length; i++) {
                    var iv = vm.product.itemValues[i];
                    if (iv.itemValueLines) {
                        for (var j = 0; j < iv.itemValueLines.length; j++) {
                            var ivl = iv.itemValueLines[j];
                            vm.itemValueObjectValues = ivl.itemValueObjectValues;
                            $scope.colspanLen = vm.itemValueObjectValues? vm.itemValueObjectValues.length +7 : 6;
                            break;
                        }
                    }
                }
            }

            if (vm.product.objectProperties !=null) {
                vm.product.defaultObjectProperties = [];
                vm.product.expandedObjectProperties = [];
                for (var i = vm.product.objectProperties.length - 1; i >= 0; i--) {
                    var ob = vm.product.objectProperties[i];
                    if (ob.propertyType == "Default") {
                        vm.properties.push(ob);
                        vm.product.defaultObjectProperties.push(ob);
                    }else if (ob.propertyType == "Expanded") {
                        vm.expandedProperties.push(ob);
                        vm.product.expandedObjectProperties.push(ob);
                    }
                }
            }

            getProductXmls();
            messageEditor();
        });


        $scope.getObjectPropertyValue = function (op) {
            if (vm.product.itemValues == null || op == null) {
                return;
            }
            var result = ListService.inList2(op, vm.propertiesBk, "propertyId", "id");
            if (result) {
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var iv = vm.product.itemValues[i];
                    var value = ListService.inList2(result, iv.objectValues, "id", "propertyId");
                    if (value != null) {
                        return value;
                    }
                }
            }

            return null;
        }

        function saleableCheck(saleable) {
            vm.product.saleable = saleable;
        }

        function pTicketCheck(b){
            vm.product.pTicket = b;
        }

        $scope.removeFile = function (field) {
            vm.product[field] = null;
        };

        $scope.removeFile2 = function (itemValue) {
            itemValue.coverImg = null;
        };

        $scope.uploadFile = function (fileWatched, uuid) {
            $timeout(function () {
                var file = fileWatched;
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.uuid == uuid) {
                                item.coverImg = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        //产品 coverImg更改
        window.updateImgFile1_2 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.coverImg2 = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        window.updateImgFile1 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.coverImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        //产品 detailImg更改
        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];

                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.detailImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
        //冲印编辑图片上传
        window.updateEditTipPic = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.editTipPic = $scope.domain + data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
        //产品 webDetailImg更改
        window.updateImgFile2 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];

                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.webDetailImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        // 模板封面图片修改
        window.xmlLineImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                       for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.productXmlLines && item.productXmlLines.length>0) {
                                for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                                    var line = item.productXmlLines[j];
                                    if (line.uuid == uuid) {
                                        line.coverImg = $scope.domain + data.key;
                                        break;
                                    }
                                }
                            }

                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        // itemvalue封面图片修改
        window.itemvalueLineImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.uuid == uuid) {
                                item.coverImg = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.goToView = function (productXmlId) {
            $(".listBGC").hide();
            $(".modal-content").hide();

            createModal('app/entities/product/product-view.html', 'ProductViewController',productXmlId).result.then(
                function (data) {

                });
        };

        var createModal = function (url, controller,id) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',

                resolve: {
                    entity: ['ProductXmlFileServes', '$stateParams', function (ProductXmlFileServes, $stateParams) {
                        return ProductXmlFileServes.get({id: id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }]
                }
            });
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go("product");
        }

        $scope.validate = function () {
            if (vm.product.name == null || vm.product.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            if (vm.product.categoryStoreId == null || vm.product.categoryStoreId == "") {
                MessageService.error("请选择分类");
                return false;
            }
            if (vm.product.bokeh && (vm.product.bokehType == null || vm.product.bokehType == '')) {
                MessageService.error("请选择虚化类型");
                return false;
            }
            if (vm.product.productType == null || vm.product.productType == "") {
                MessageService.error("请选择类型");
                return false;
            }
            if(vm.product.productDetailType != 'RTF'){
                if (vm.product.coverImg == null || vm.product.coverImg == "") {
                    MessageService.error("请上传商品图片");
                    return false;
                }
                if (vm.product.detailImg == null || vm.product.detailImg == "") {
                    MessageService.error("请上完善商品详情");
                    return false;
                }
            }else{
                if (vm.product.richText == null || vm.product.richText == "") {
                    MessageService.error("请完善富文本编辑内容");
                    return false;
                }
            }

            if (vm.product.fileFormat != 'PDF') {
                vm.product.flatten = false;
            }

            if (vm.product.productType == 'CustomPrint' &&  !vm.product.editorType  ) {
                MessageService.error("请录入编辑器类型");
                return false;
            }
            if(vm.product.editorType == 'PICTURE' || vm.product.editorType == 'PICTURE_PRO' || vm.product.editorType== 'NEW_EDITOR' || vm.product.editorType == 'CALENDAR_EDITOR'){
                if(vm.product.editTip && !vm.product.editTipPic){
                    MessageService.error("编辑提示图片不能为空");
                    return false;
                }
            }

            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var item = vm.product.itemValues[i];
                if (item.cancelled || item.deleted) {
                    continue;
                }
                if (item.price == null || item.price === "" || item.price > 1000000 || item.price < 0) {
                    MessageService.error("请输入有效价格");
                    return false;
                }
                if (vm.product.jhiPackage && (!item.packageQuantity || item.packageQuantity > 10000 || item.packageQuantity < 0) ) {
                    MessageService.error("请输入有效的成套数");
                    return false;
                }

                for (var j = item.objectValues.length - 1; j >= 0; j--) {
                    var value = item.objectValues[j];
                    if (value.propertyValueId == null || value.propertyValueId == "") {
                        MessageService.error("组合中，请选择规格值");
                        return false;
                    }
                }
                if (!$scope.validateItemValueLinevalues(item)) {
                    MessageService.error("同一规格行中，扩展规格不能选填一样的组合值");
                    return false;
                }
                if (item.itemValueLines && item.itemValueLines.length> 0) {
                    for (var j = item.itemValueLines.length - 1; j >= 0; j--) {
                        var line = item.itemValueLines[j];
                        if (line.cancelled) {
                            continue;
                        }
                        if (line.itemValueObjectValues && line.itemValueObjectValues.length>0) {
                            for (var z = line.itemValueObjectValues.length - 1; z >= 0; z--) {
                                var v = line.itemValueObjectValues[z];
                                if (v.propertyValueValue == null || v.propertyValueValue == "" || v.propertyValueId == null || v.propertyValueId == "") {
                                    MessageService.error("扩展规格组合中，请选择规格值");
                                    return false;
                                }
                            }
                        }

                    }
                }

                if (item.productXmlLines && item.productXmlLines.length>0) {
                    for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                        var xml = item.productXmlLines[j];
                        if(!xml.deleted) {
                            if (xml.name == null || xml.name == "") {
                                MessageService.error("请为模板设置展示名称");
                                return false;
                            }
                            if (xml.coverImg == null || xml.coverImg == "") {
                                MessageService.error("请为模板设置展示图");
                                return false;
                            }
                            if (vm.product.productType == "CustomPrint") {
                                // if (xml.musicId == null || xml.musicId == "") {
                                //     MessageService.error("请为模板设置展示音乐");
                                //     return false;
                                // }
                                if (xml.productXmlId == null || xml.productXmlId == "") {
                                    MessageService.error("请选择模板");
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
            return true;
        };

        $scope.validateItemValueLinevalues = function(item){
            var values = [];
            if (item.itemValueLines == null || item.itemValueLines.length == 0) {
                return true;
            }
            for (var i = item.itemValueLines.length - 1; i >= 0; i--) {
                var line = item.itemValueLines[i];
                if (line.cancelled || line.deleted ||line.itemValueObjectValues == null || line.itemValueObjectValues.length == 0) {
                    continue;
                }
                var vals = "";
                for (var z = line.itemValueObjectValues.length - 1; z >= 0; z--) {
                    var v = line.itemValueObjectValues[z];
                    vals += v.propertyValueValue;
                }
                if ( values.indexOf(vals)>=0) {
                    return false;
                }else{
                    values.push(vals);
                }
            }
            return true;
        }

        function save() {
            if (!$scope.validate()) {
                return;
            }
            vm.product.objectProperties = [];
            if (vm.product.defaultObjectProperties &&  vm.product.defaultObjectProperties.length>0 ) {
                for (var i = vm.product.defaultObjectProperties.length - 1; i >= 0; i--) {
                    var op = vm.product.defaultObjectProperties[i];
                    vm.product.objectProperties.push(op);
                }
            }
            if (vm.product.expandedObjectProperties && vm.product.expandedObjectProperties.length>0) {
                for (var i = vm.product.expandedObjectProperties.length - 1; i >= 0; i--) {
                    var op = vm.product.expandedObjectProperties[i];
                    vm.product.objectProperties.push(op);
                }
            }

            // 提交数据删除itemValue中的模版列表
            var post = angular.copy(vm.product);
            for (var i = 0; i < post.itemValues.length; i++) {
                var itemValue = post.itemValues[i];
                if (itemValue && itemValue.productXmls) {
                    delete itemValue.productXmls;
                }
            }
            post.periodOfValidity = DateUtils.convertLocalDateToServer(post.periodOfValidity);
            vm.isSaving = true;
            if (vm.product.id !== null) {
                Product.update(post, onSaveSuccess, onSaveError);
            } else {
                Product.save(post, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:productUpdate', result);
            MessageService.success("保存成功");
            vm.isSaving = false;
            $state.go("product");
        }

        function onSaveError(result) {
            vm.isSaving = false;
            MessageService.error(result.data.message|| "保存失败");
        }



        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image-dialog").removeClass('hide1');
            $(".dialog-form").addClass('opacity-zero');
            $(".origin-image-dialog img").attr('src', pic);
            $(".modal-content,.modal-dialog").addClass('clear-model');
        }

        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image-dialog").addClass('hide1');
            $(".dialog-form").removeClass('opacity-zero');
            $(".modal-content,.modal-dialog").removeClass('clear-model');
            $('body').css("overflow","auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStartDialog = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOnDialog, true);
            document.addEventListener("mouseup", moveImgEndDialog, true);
        }

        //move事件
        function moveImgOnDialog(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEndDialog() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOnDialog, true);
            document.removeEventListener("mouseup", moveImgEndDialog, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        vm.datePickerOpenStatus.periodOfValidity = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }


        /**
         * 商品模板选择
         * @type {null}
         */
        var firstMouldId = null;
        $scope.mouldChange = function () {
            // 判断是否选中了相同的参数
            if(firstMouldId!=null&&firstMouldId==vm.product.mouldId){
                return
            }

            firstMouldId=vm.product.mouldId;

            // 模板更新后，规格也更新
            vm.properties=[];
            vm.expandedProperties=[];
            vm.product.objectProperties = [];
            vm.product.itemValues = [];
            vm.product.defaultObjectProperties =[];
            vm.product.expandedObjectProperties = [];
            angular.forEach(vm.moulds,function (m) {
                if(m.id==vm.product.mouldId){
                    vm.product.categoryId = m.categoryId;
                    vm.product.categoryName = m.categoryName;
                    vm.product.categoryStoreId = m.categoryId;
                    vm.product.categoryStoreName = m.categoryName;
                    angular.forEach(m.mouldProperties,function (ob) {
                        if(!ob.cancelled){
                            var uuid = UidService.get();
                            var objectProperty = {
                                propertyId: ob.propertyId,
                                propertyName: ob.propertyName,
                                seq: uuid,
                                uuid:uuid,
                            }
                            if (ob.propertyType == "Default") {
                                vm.product.defaultObjectProperties.push(objectProperty);
                                vm.properties.push(ob);
                            }else if (ob.propertyType == "Expanded") {
                                vm.expandedProperties.push(ob);
                                vm.product.expandedObjectProperties.push(objectProperty);
                            }
                        }
                    })
                }
            })
        }

        $scope.categoryChange = function(){
            getProductXmls();
        }

        $scope.deleteItemValue = function (itemValue) {
            if (!itemValue.added) {
                itemValue.deleted = true;
            } else {
                var ind = vm.product.itemValues.indexOf(itemValue);
                if (ind >= 0) {
                    vm.product.itemValues.splice(ind, 1);
                }
            }
        }

        /**
         * 规格生成弹窗中的选择框
         * @param property
         * @param value
         */
        var arrsDefault = [],arrsExpanded=[];
        $scope.valueClick = function (property, value) {
            value.selected = !value.selected;
            if(property.propertyType== "Default"){
                leadPropertiesClickStatus(vm.properties,property,value,arrsDefault)
                return
            }
            leadPropertiesClickStatus(vm.expandedProperties,property,value,arrsExpanded)
        }

        function leadPropertiesClickStatus(allProperties,property,value,arrs) {
            // 一级规格
            if(property.propertyLead){
                angular.forEach(allProperties,function (p) {
                    angular.forEach(p.propertyValues,function (v) {
                        if(value.id==v.leadPropertyValueId){
                            v.selected = value.selected;
                        }
                    })
                })
                return
            }

            var i = 0;
            var arr = [];
            // 二级规格
            if(value.leadPropertyValueId){
                angular.forEach(allProperties,function (p) {
                    angular.forEach(p.propertyValues,function (v) {
                        if(value.leadPropertyValueId==v.id){
                            arr = v;
                        }
                        if(value.selected&&value.leadPropertyValueId==v.id){
                            v.selected = true;
                        }

                        if(value.leadPropertyValueId==v.leadPropertyValueId&&v.selected){
                            i++;
                        }
                    })
                })
            }
            if(i==0){
                arr.selected = false;
            }
        }


        /**
         * 规格生成弹窗中的 生成 按钮
         */
        $scope.propertyGener = function () {
            vm.defalutList = [];
            vm.expandedList = [];
            angular.forEach(vm.properties,function (p) {
                var obj={
                    propertyName:p.propertyName,
                    id:p.id,
                    uuid: UidService.get(),
                    propertyType:p.propertyType,
                    propertyValues:[]
                }
                angular.forEach(p.propertyValues,function (v) {
                    if(v.selected){
                        obj.propertyValues.push(v);
                    }
                })
                if(obj.propertyValues.length){
                    vm.defalutList.push(obj)
                }
            })
            angular.forEach(vm.expandedProperties,function (p) {
                var obj={
                    propertyName:p.propertyName,
                    id:p.id,
                    uuid: UidService.get(),
                    propertyType:p.propertyType,
                    propertyValues:[]
                }
                angular.forEach(p.propertyValues,function (v) {
                    if(v.selected){
                        obj.propertyValues.push(v);
                    }
                })
                if(obj.propertyValues.length){
                    vm.expandedList.push(obj)
                }
            })
            if(vm.properties.length!=vm.defalutList.length){
                MessageService.error("请选取默认规格")
            }else if(vm.expandedProperties.length!=vm.expandedList.length){
                MessageService.error("请选取扩展规格")
            }else{
                vm.propertyShow = !vm.propertyShow;
                $scope.propertyValueComfirm(vm.expandedList,vm.defalutList)
            }
            getProductXmls();
        }

        var items = [];
        function getProductXmls() {
            items = [
                {key: "category.id", op: "=", value: vm.product.categoryStoreId},
                {key: "storeId", op: "=", value: vm.store.id},
                {key: "status", op: "=", value: 'Approved'}
            ];
            if(vm.product.productType=='IPDerive'){
                items.push({key: "xmlType", op: "=", value: 'PRODUCT'})
            }else{
                items.push({key: "xmlType", op: "=", value: 'XML'})
            }

            ProductXmls.getAllByCondition({
                items: items
            }, function (data) {
                vm.productXmlsAll = data;
                for(var x = 0;x<vm.product.itemValues.length;x++){
                    var itemValue = vm.product.itemValues[x];
                    if (itemValue.cancelled|| itemValue.deleted) {
                        continue;
                    }
                    itemValue.productXmls = angular.copy(data);
                    var a = [];
                    if(!!itemValue.productXmlLines){
                        for(var i = 0;i < itemValue.productXmlLines.length;i++){
                            var line = itemValue.productXmlLines[i];
                            if(line.cancelled){
                                continue
                            }
                            a.push(line.productXmlId);
                        }
                    }
                    var aStr = a.toString();
                    for (var i = 0, len = itemValue.productXmls.length; i < len; i++) {
                        var xml = itemValue.productXmls[i];
                        if (aStr.indexOf(xml.id) != -1) {
                            xml.choose = true;
                        }
                    }
                }
            });
        }
        /**
         * 规格生成弹窗显示与隐藏
         */
        $scope.modalHide = function () {
            if (vm.product.productType == null || vm.product.productType == "") {
                MessageService.error("请先选择类型")
                return;
            }
            vm.propertyShow = !vm.propertyShow;
            // 记录勾选的规格值
            if(vm.propertyShow){
                vm.propertiesAll = angular.copy(vm.properties)||[];
                vm.expandedPropertiesAll = angular.copy(vm.expandedProperties)||[];
            }else{
                vm.properties = angular.copy(vm.propertiesAll)||[];
                vm.expandedProperties = angular.copy(vm.expandedPropertiesAll)||[];
            }
        }



        /**
         * 模板选择弹窗显示与隐藏
         * @param itemValue    vm.product.itemValue
         * @param line     productXmlLine
         */
        $scope.xmlOpen = function (itemValue, line) {
            vm.searchQuery = "";
            vm.xmlShow = !vm.xmlShow;
            vm.themeClassifies = [];
            vm.themeId = null;
            vm.dynamic = "";
            vm.currentXmlLine = line;
            var selectedId= null;
            angular.forEach(itemValue.productXmls, function (xml) {
                // 所有行置于未选择状态
                xml.select = false;
                xml.filter = false;

                xml.themeClassifies.forEach(function (themeClassifie) {
                    var theme = {id:themeClassifie.id, name:themeClassifie.name};
                    var result = ListService.inList(theme, vm.themeClassifies, ["id"]);
                    if (result == null) {
                        vm.themeClassifies.push(theme);
                    }
                })
                // 若该行已被选中
                if (xml.id == line.productXmlId) {
                    vm.thisXmlLine = xml;
                    xml.modelBlendentUuid = line.modelBlendentUuid;
                    xml.select = true;
                    selectedId = xml.id;
                }
            })
            $scope.selectItemValue = itemValue;
            if (!!selectedId) {
                setTimeout(function() {
                    document.getElementById(selectedId).scrollIntoView();
                }, 100);
            }

        }

        $scope.xmlHide = function () {
            vm.xmlShow = !vm.xmlShow;
            vm.searchQuery = "";
            vm.themeId = null;
            vm.dynamic = "";
            angular.forEach($scope.selectItemValue.productXmls, function(xml){
                xml.filter = false;
            });
            vm.addXml = false;
            vm.currentXmlLine = {};
            $scope.selectItemValue = {};
        }

        /**
         * 选中模板文件
         * @param xml
         */
        $scope.chooseOne = function (xml) {
            xml.select = !xml.select;
            if (!vm.addXml) {
                angular.forEach($scope.selectItemValue.productXmls, function (p) {
                    if (p.id != xml.id) {
                        p.select = false;
                    }
                })
            }
        }

        /**
         * 模板文件搜索
         * @param searchQuery
         */
        function search(searchQuery) {
            angular.forEach($scope.selectItemValue.productXmls, function(xml){
                xml.filter = false;
            });

            if (!!searchQuery) {
                angular.forEach($scope.selectItemValue.productXmls, function(xml){
                    if (!xml.filter &&  xml.name.indexOf(searchQuery)<0) {
                        xml.filter = true;
                    }
                })
            }
            if (!!vm.themeId) {
                angular.forEach($scope.selectItemValue.productXmls, function(xml){
                    if (!xml.filter) {
                        var _exist = false;
                        for(var i=0; i<xml.themeClassifies.length; i++){
                            if(xml.themeClassifies[i].id == vm.themeId){
                                _exist = true;
                                break
                            }
                        }
                        if(!_exist){
                            xml.filter = true;
                        }
                    }
                })
            }

            if (!!vm.dynamic) {
                var dynamic = (vm.dynamic == "true") ? true : (vm.dynamic == "false") ? false : null;
                angular.forEach($scope.selectItemValue.productXmls, function(xml){
                    if (!xml.filter && (xml.dynamic != dynamic) ) {
                        xml.filter = true;
                    }
                })
            }

        }

        $scope.xmlGener = function () {
            //编辑模板行
            vm.searchQuery = "";
            vm.themeId = null;
            vm.dynamic = "";
            angular.forEach($scope.selectItemValue.productXmls, function(xml){
                xml.filter = false;
            });
            if (!vm.addXml) {
                // 该行未选择则置为false
                if(vm.thisXmlLine&&!vm.thisXmlLine.select){
                    vm.thisXmlLine.choose = false;
                }
                vm.currentXmlLine.productXmlId = null;
                vm.currentXmlLine.productXmlName = null;
                angular.forEach($scope.selectItemValue.productXmls, function (p) {
                    if(p.select){
                        p.choose = true;
                        vm.currentXmlLine.name = p.name;
                        vm.currentXmlLine.productXmlId = p.id;
                        vm.currentXmlLine.productXmlName = p.name;
                        vm.currentXmlLine.productXmlNumber = p.number;
                        if (p.modelBlendent && p.modelBlendentUuid) {
                            for (var i = p.modelBlendent.length - 1; i >= 0; i--) {
                               var modelBlendent = p.modelBlendent[i];
                               if (modelBlendent.uuid == p.modelBlendentUuid) {
                                    vm.currentXmlLine.modelBlendentUuid = modelBlendent.uuid;
                                    vm.currentXmlLine.modelBlendentName = modelBlendent.name;
                                    break;
                               }
                            }
                        }else{
                            vm.currentXmlLine.modelBlendentUuid = null;
                            vm.currentXmlLine.modelBlendentName = null;
                        }
                        ProductXmls.findOne({id: p.id},function (item) {
                            if(item.documentPics.length){
                                item.documentPics.sort(function(a, b){
                                    return a.seq-b.seq;
                                })
                                vm.currentXmlLine.coverImg = item.documentPics[0].identifier;
                            }
                        })
                    }
                })
            }else{
                //新增模板行
                if($scope.selectItemValue.productXmlLines==undefined){
                    $scope.selectItemValue.productXmlLines = [];
                }

                angular.forEach($scope.selectItemValue.productXmls, function (xml) {
                    if(xml.select){
                        xml.choose = true;
                        var xmlLine = {
                            name:xml.name,
                            added:true,
                            seq:999,
                            productXmlId:xml.id,
                            productXmlName:xml.name,
                            productXmlNumber: xml.number,
                            uuid:UidService.get(),
                        }
                        if (xml.modelBlendent && xml.modelBlendentUuid) {
                            for (var i = xml.modelBlendent.length - 1; i >= 0; i--) {
                               var modelBlendent = xml.modelBlendent[i];
                               if (modelBlendent.uuid == xml.modelBlendentUuid) {
                                    xmlLine.modelBlendentUuid = modelBlendent.uuid;
                                    xmlLine.modelBlendentName = modelBlendent.name;
                                    break;
                               }
                            }
                        }else{
                            xmlLine.modelBlendentUuid = null;
                            xmlLine.modelBlendentName = null;
                        }
                        ProductXmls.findOne({id: xml.id},function (item) {
                            if(item.documentPics.length){
                                item.documentPics.sort(function(a, b){
                                    return a.seq-b.seq;
                                })
                                xmlLine.coverImg = item.documentPics[0].identifier;
                            }
                            if ($scope.selectItemValue.productXmlLines.length == 1 && vm.product.productType == 'IPDerive'){
                                xml.select = false;
                                xml.choose = false;
                            }
                            else {
                                $scope.selectItemValue.productXmlLines.push(xmlLine);
                            }
                        })
                    }
                })
            }
            vm.addXml = false;
            vm.xmlShow = false;
        }

        $scope.fileFormatChange = function () {
            if(vm.product.fileFormat=='PDF'){
                vm.product.spreadType = null;
                vm.product.makeupType = null;
            }else{
                vm.product.spreadType='None';
            }
        }

        $scope.spreadTypeChange = function () {
            if(vm.product.spreadType=='Spread'){
                vm.product.makeupType='Mounting'
            }else{
                vm.product.makeupType = null;
            }
        }

        function doExchange(results, result, arr, depth, value) {
            for (var i = 0; i < arr[depth][value].length; i++) {
                result[depth] = arr[depth][value][i];
                if (depth != arr.length - 1) {
                    doExchange(results, result, arr, depth + 1, value);
                } else {
                    var t = angular.copy(result);
                    results.push(t);
                }
            }
        }

        $scope.propertyValueComfirm = function(expandedProperties, defaultProperties) {
            var results = [];
            var result = [];
            var defaultPropertiesBack = angular.copy(defaultProperties);
            for (var i = defaultPropertiesBack.length - 1; i >= 0; i--) {
                var p =  defaultPropertiesBack[i];
               for (var j = p.propertyValues.length - 1; j >= 0; j--) {
                   var pValue = p.propertyValues[j];
                   if (!pValue.selected) {
                        p.propertyValues.splice(j,1);
                    }
               }
            }
            //确认将有多少行 ItemValue
            doExchange(results, result, defaultPropertiesBack, 0, "propertyValues");
            var itemValues = [];
            for (var i = results.length - 1; i >= 0; i--) {
                var objectvalues = results[i];
                var itemValue = {
                    objectValues: [],
                    productXmls:[]
                };
                var sku = "";
                var skuNumber = "";

                for (var j = objectvalues.length - 1; j >= 0; j--) {
                    var ob = objectvalues[j];
                    var uuid = UidService.get();
                    sku = sku + "," + ob.value;
                    skuNumber = skuNumber + "-" + ob.propertyId + "_" + ob.id;
                    var objValue = {
                        seq: uuid,
                        uuid: uuid,
                        propertyId: ob.propertyId,
                        propertyName: ob.propertyName,
                        propertyValueId: ob.id,
                        propertyValueValue: ob.value
                    }
                    itemValue.objectValues.push(objValue);
                }
                itemValue.skuNumber = skuNumber.substring(1);
                itemValue.uuid = UidService.get();
                itemValue.sku = sku.substring(1);
                itemValue.storeId =vm.store.id;
                itemValues.push(itemValue);
            }
            console.log(itemValues)


            results = [];
            result = [];
            var expandedPropertiesBack = angular.copy(expandedProperties);
            if (expandedPropertiesBack!= null && expandedPropertiesBack.length>0) {
                for (var i = expandedPropertiesBack.length - 1; i >= 0; i--) {
                    var p =  expandedPropertiesBack[i];
                   for (var j = p.propertyValues.length - 1; j >= 0; j--) {
                       var pValue = p.propertyValues[j];
                       if (!pValue.selected) {
                            p.propertyValues.splice(j,1);
                        }
                   }
                }
                //确认将有多少行 ItemValue
                doExchange(results, result, expandedPropertiesBack, 0, "propertyValues");
                for (var i = itemValues.length - 1; i >= 0; i--) {
                    var itemValue = itemValues[i];
                    var itemValueLines = [];
                    for (var j = results.length - 1; j >= 0; j--) {
                        var objectvalues = results[j];
                        var sku = itemValue.sku;
                        var skuNumber = itemValue.skuNumber;
                        var itemValueLine = {
                            itemValueObjectValues: []
                        };
                        for (var z = objectvalues.length - 1; z >= 0; z--) {
                            var ob = objectvalues[z];
                            var uuid = UidService.get();
                            sku = sku + "," + ob.value;
                            skuNumber = skuNumber + "-" + ob.propertyId + "_" + ob.id;
                            var objValue = {
                                seq: uuid,
                                uuid: uuid,
                                propertyId: ob.propertyId,
                                propertyName: ob.propertyName,
                                propertyValueId: ob.id,
                                propertyValueValue: ob.value
                            }
                            itemValueLine.itemValueObjectValues.push(objValue);
                        }
                        itemValueLine.saleable = true;
                        itemValueLine.storeId =vm.store.id;
                        itemValueLine.skuNumber = skuNumber;
                        itemValueLine.sku = sku;
                        itemValueLine.uuid = UidService.get();
                        itemValueLines.push(itemValueLine);
                    }
                    itemValue.itemValueLines = itemValueLines;
                }
            }else{
                for (var i = itemValues.length - 1; i >= 0; i--) {
                    var itemValue = itemValues[i];
                    itemValue.itemValueLines = [{
                        saleable:true,
                        sku: itemValue.sku,
                        skuNumber: itemValue.skuNumber,
                        uuid:UidService.get()
                    }];
                }
            }
            //判断ItemValue 或者 ItemValueLine 是否要加入product
            for (var i = itemValues.length - 1; i >= 0; i--) {
                var item = itemValues[i];
                var result = $scope.getItemFromProduct(item);
                if (result == null) {
                    if (vm.product.itemValues == null) {
                        vm.product.itemValues = [];
                    }
                    if (vm.product.productType == "StandardPrint"|| vm.product.productType == "Standard" ) {
                        item.productXmlLines = [{
                            name:"",
                            uuid:UidService.get()
                        }];
                    }
                    vm.product.itemValues.push(item);
                }else{
                    $scope.updateItemValueLineFromItem(result, item);
                }
            }
            if (vm.product.itemValues) {
                for (var i = 0; i < vm.product.itemValues.length; i++) {
                    var iv = vm.product.itemValues[i];
                    if (iv.itemValueLines) {
                        for (var j = 0; j < iv.itemValueLines.length; j++) {
                            var ivl = iv.itemValueLines[j];
                            vm.itemValueObjectValues = ivl.itemValueObjectValues;
                            $scope.colspanLen = vm.itemValueObjectValues? vm.itemValueObjectValues.length +6 : 7;
                            break;
                        }
                    }
                }
            }
        }


        $scope.updateItemValueLineFromItem = function(item, it){
            item.sku = it.sku;
            for (var j =  it.itemValueLines.length - 1; j >= 0; j--) {
                var itemValueLine = it.itemValueLines[j];
                if (itemValueLine.cancelled || itemValueLine.deleted) {
                    continue;
                }
                var result =  $scope.getItemValueLineFromItem(item, itemValueLine);
                if (result == null) {
                    item.itemValueLines.push(itemValueLine);
                } else {
                    result.sku = itemValueLine.sku;
                }
            }
        }

        $scope.getItemValueLineFromItem = function(item, line){
            if (item.itemValueLines== null || item.itemValueLines.length == 0) {
                return null;
            }
            for (var i = item.itemValueLines.length - 1; i >= 0; i--) {
                var l = item.itemValueLines[i];
                if (l.cancelled || l.deleted) {
                    continue;
                }
                if (l.skuNumber == line.skuNumber) {
                    return l;
                }
            }
            return null;
        }

        $scope.getItemFromProduct = function(it){
            if (vm.product.itemValues == null || vm.product.itemValues.length == 0) {
                return null;
            }
            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var item =  vm.product.itemValues[i];
                if (item.deleted || item.cancelled) {
                    continue;
                }
                if (it.skuNumber == item.skuNumber) {
                   return item;
                }
            }
            return null;
        }

        $scope.addXml = function (itemValue) {
            // if (itemValue.productXmlLines.length == 1 && vm.product.productType == 'IPDerive'){
            //     MessageService.notice("IP衍生品仅可设置一个模板")
            //     return
            // }
            vm.addXml = true;
            vm.xmlShow = !vm.xmlShow;
            vm.themeClassifies = [];
            vm.themeId = null;
            vm.dynamic = "";
            angular.forEach(itemValue.productXmls, function (xml) {
                // 所有行置于未选择状态
                xml.select = false;
                xml.themeClassifies.forEach(function (themeClassifie) {
                    var theme = {id:themeClassifie.id, name:themeClassifie.name};
                    var result = ListService.inList(theme, vm.themeClassifies, ["id"]);
                    if (result == null) {
                        vm.themeClassifies.push(theme);
                    }
                })
            })
            $scope.selectItemValue = itemValue;
            console.log(vm.product.itemValues);
        }


        window.updateProductPics = function ($event) {
            var files = $event.target.files;
            var result = [];
            angular.forEach(files,function (file) {
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + file.name + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        result.push({ key:data.key});
                        if (result.length == files.length) {
                            result.sort(function(a, b){
                                return a.key.localeCompare(b.key);
                            })
                            if (vm.product.productPics == null) {
                                vm.product.productPics = [];
                            }
                            for (var j = 0 ; j< result.length ; j++) {
                                var seq = vm.product.productPics.length+1;
                                var r = result[j];
                                vm.product.productPics.push({
                                    sort:seq,
                                    imgUrl:r.key,
                                });
                            }
                            $scope.$digest();
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            })
        };

        $scope.removeProductPic = function (pic,index) {
            if(pic.id){
                pic.cancelled = true;
            }else{
                vm.product.productPics.splice(index,1);
            }
        };

        vm.videoLoading = false;
        vm.videoPercent = 0;
        window.updateVideo = function ($event) {
            vm.videoLoading = true;
            vm.videoPercent = 0;
            var config = {
                useCdnDomain: true,
                disableStatisticsReport: false,
                retryCount: 6,
                region: qiniu.region.z0
            };
            var putExtra = {
                fname: "",
                params: "",
                mimeType: null
            };
            var observer = {
                next: function(res) {
                    vm.videoLoading = true;
                    console.log("已上传：" + res.total.percent);
                    vm.videoPercent = parseInt(res.total.percent);
                    $scope.$apply();
                },
                error: function(err) {
                    vm.videoLoading = false;
                    console.log("上传发生问题：" + err);
                },
                complete: function(res) {
                    vm.videoLoading = false;
                    vm.product.videoUrl = res.key;
                    $scope.$apply();
                }
            }
            var file = $event.target.files[0];
            var key = "product-" + UidService.get();
            var observable = qiniu.upload(file, key, vm.token, putExtra, config);
            observable.subscribe(observer);
        }

        $scope.videoUrlFun = function(url){
            //$sce.trustAsResourceUrl方法把普通路径处理加工成一个angular环境可识别，并认为是安全的路径来使用
            var urlFun = $sce.trustAsResourceUrl(url);
            return urlFun;
        };

        $scope.removeVideo = function () {
            vm.product.videoUrl = null;
        }

        $scope.showSupplierProduct = function (line) {
            vm.suplierProducts = true;
            vm.currentItemValueLine = line;

            $uibModal.open({
                templateUrl: 'app/entities/product/modal/supplier-item-select.html',
                controller: 'SupplierItemSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {}
                    }
                }
            }).result.then(function(result){
                vm.currentItemValueLine.supNumber = result;
            });
        }

        //富文本

        function messageEditor() {
            var E = window.wangEditor;
            var editor = new E('#messageEditor')
            editor.customConfig.onchange = function (html) {
                vm.product.richText = html;
            };
            editor.customConfig.uploadImgServer = 'https://upload.qiniup.com/';
            editor.customConfig.uploadImgParams = {
                key: UidService.get(),
                token: vm.token,
                unique_names: false
            };
            editor.customConfig.uploadFileName = 'file';
            editor.customConfig.uploadImgHooks = {
                before: function (xhr, editor, files) {
                    editor.config.uploadImgParams.key= UidService.get();
                    console.log("111111111111")
                },
                customInsert: function (insertImg, result, editor) {
                    var url = $scope.domain + result.key;
                    insertImg(url);
                    console.log("222222222222")
                }
            };
            editor.customConfig.customAlert = function (info) {
                MessageService.error(info)
            };
            editor.create();
            editor.txt.html(vm.product.richText);

        }
        // 快速生成价格
        vm.skuPrice;
        $scope.priceAdd=function(){
         console.log(vm.product.itemValues);
         vm.product.itemValues.forEach(function(item){
            item.price=vm.skuPrice-0
         })
        }
        //关联模型
        $scope.addGltf = function () {
            $uibModal.open({
                templateUrl: 'app/entities/scene/scene-modal.html',
                controller: 'SceneModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return vm.product.moulds
                    }
                }
            }).result.then(function(result){
                if(result){
                    vm.product.moulds = result;
                }
            });
        }
        $scope.deleteMould = function (index) {
            vm.product.moulds.splice(index,1);
        }

        //产品 coverImg更改
        window.updateProjectImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.projectImg = $scope.domain + data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.themeClassifie = function (xml) {
            var _return = '';
            xml.themeClassifies.forEach(function (item) {
                _return = _return + item.name + '；'
            })
            if(_return){
                _return = _return.substring(0, _return.length - 1);
            }
            return _return;
        }
    }
})();
