(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductDialogController', ProductDialogController);

    ProductDialogController.$inject = ['$timeout', '$scope', '$state', '$q', 'entity', 'Product', 'Category', 'ListService', 'UidService','$sce',
        'MessageService', 'Qinius', 'Property', 'ProductXmls', 'ProductXmlFileServes','$uibModal', 'Music', 'Mould','PlatformWechatSetting','Store',
        'RegionTemp','StoreAbutments','Principal', 'DiyEditorSetting', "TaskbillForms", 'DateUtils'];


    function ProductDialogController($timeout, $scope, $state, $q, entity, Product, Category, ListService, UidService, $sce,
        MessageService, Qinius, Property, ProductXmls, ProductXmlFileServes,$uibModal, Music, Mould, PlatformWechatSetting, Store,
        RegionTemp, StoreAbutments,Principal, DiyEditorSetting, TaskbillForms, DateUtils) {
        var vm = this;
        localStorage.setItem('Production', 'edit');
        $scope.status = localStorage.getItem('Production');
        vm.product = entity;
        vm.clear = clear;
        vm.save = save;
        $scope.colspanLen = 5;
        vm.pTicketCheck = pTicketCheck;
        vm.saleableCheck = saleableCheck;
        vm.platformWechatSetting = PlatformWechatSetting.get();
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();

        vm.categories = Category.storeGetAllByCondition({items: [{key:"usable",op:"=",value:'true'}]})

        vm.diyEditorSettings = DiyEditorSetting.getAllByCondition({
            items: [{key: "cancelled", op: "=", value: "false"}]
        });

        $scope.removeProductPic = function (pic,index) {
            if(pic.id){
                pic.cancelled = true;
            }else{
                vm.product.productPics.splice(index,1);
            }
        };


        window.updateProductPics = function ($event) {
            var files = $event.target.files;
            var result = [];
            angular.forEach(files,function (file) {
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + file.name + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        result.push({ key:data.key});
                        if (result.length == files.length) {
                            result.sort(function(a, b){
                                return a.key.localeCompare(b.key);
                            })
                            if (vm.product.productPics == null) {
                                vm.product.productPics = [];
                            }
                            for (var j = 0 ; j< result.length ; j++) {
                                var seq = vm.product.productPics.length+1;
                                var r = result[j];
                                vm.product.productPics.push({
                                    sort:seq,
                                    imgUrl:r.key,
                                });
                            }
                            $scope.$digest();
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            })
        };

        vm.videoLoading = false;
        vm.videoPercent = 0;
        window.updateVideo = function ($event) {
            vm.videoLoading = true;
            vm.videoPercent = 0;
            var config = {
                useCdnDomain: true,
                disableStatisticsReport: false,
                retryCount: 6,
                region: qiniu.region.z0
            };
            var putExtra = {
                fname: "",
                params: "",
                mimeType: null
            };
            var observer = {
                next: function(res) {
                    vm.videoLoading = true;
                    console.log("已上传：" + res.total.percent);
                    vm.videoPercent = parseInt(res.total.percent);
                    $scope.$apply();
                },
                error: function(err) {
                    vm.videoLoading = false;
                    console.log("上传发生问题：" + err);
                },
                complete: function(res) {
                    vm.videoLoading = false;
                    vm.product.videoUrl = res.key;
                    $scope.$apply();
                }
            }
            var file = $event.target.files[0];
            var key = "product-" + UidService.get();
            var observable = qiniu.upload(file, key, vm.token, putExtra, config);
            observable.subscribe(observer);
        }

        $scope.videoUrlFun = function(url){
            //$sce.trustAsResourceUrl方法把普通路径处理加工成一个angular环境可识别，并认为是安全的路径来使用
            var urlFun = $sce.trustAsResourceUrl(url);
            return urlFun;
        };

        $scope.removeVideo = function () {
            vm.product.videoUrl = null;
        }


        function messageEditor() {
            var E = window.wangEditor;
            var editor = new E('#messageEditor')
            editor.customConfig.onchange = function (html) {
                vm.product.richText = html;
            };
            editor.customConfig.uploadImgServer = 'https://upload.qiniup.com/';
            editor.customConfig.uploadImgParams = {
                key: UidService.get(),
                token: vm.token,
                unique_names: false
            };
            editor.customConfig.uploadFileName = 'file';
            editor.customConfig.uploadImgHooks = {
                before: function (xhr, editor, files) {
                    editor.config.uploadImgParams.key= UidService.get();
                },
                customInsert: function (insertImg, result, editor) {
                    var url = $scope.domain + result.key;
                    insertImg(url);
                }
            };
            editor.customConfig.customAlert = function (info) {
                MessageService.error(info)
            };
            editor.create();
            editor.txt.html(vm.product.richText);
        }

        $scope.editorTypeChange = function(){
            if (vm.product.editorType !='NEW_EDITOR') {
                vm.product.forceFill = false;
                vm.product.aiFill = false;
            }
            if (vm.product.editorType != 'PICTURE' && vm.product.editorType != 'PICTURE_PRO') {
                vm.product.jhiPackage = false;
            }
        }

        $scope.aiFillChange = function(){
            if (vm.product.aiFill) {
                vm.product.forceFill = false;
            }
        }

        $scope.forceFillChange = function(){
            if (vm.product.forceFill) {
                vm.product.aiFill = false;
            }
        }

        $scope.updatePromotion = function (line) {
            createModal('app/entities/product/product-promotion-dialog.html', 'StoreProductPromotionDialogController', line).result.then(
                function (data) {
                    line = data
                });
        }

        $scope.priceAdd=function(){
            vm.product.itemValues.forEach(function(item){
                item.price=vm.skuPrice-0
            })
        }

        var createModal = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: item,
                }
            });
        };

         $scope.itemvalueDefault =  function(itemValue){
            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var value = vm.product.itemValues[i];
                if (value.id == itemValue.id) {
                    continue;
                }
                value.default = false;
            }
        }


        $scope.itemvalueLineDefault =  function(itemValue, line){
            for (var i = itemValue.itemValueLines.length - 1; i >= 0; i--) {
                var valueLine = itemValue.itemValueLines[i];
                if (line.id == valueLine.id) {
                    continue;
                }
                valueLine.default = false;
            }
        }


        $scope.amountChange = function (itemValue, amount) {
            if (itemValue == null || itemValue[amount] == null) {
                return;
            }
            if (!(/^-?\d+\.?\d{0,2}$/.test(itemValue[amount]))) {
                MessageService.error("金额只能输入小数点后两位数");
                itemValue[amount] = Math.round(itemValue[amount] * 100) / 100;
                return;
            }
        };

        $scope.thirdUrlCreated = function () {
            vm.product.thirdUrl = vm.platformWechatSetting.storeNotifyUrl + "/#/" + vm.product.storeId + "/" + vm.product.id + "/product";
        }


        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.product.$promise, vm.store.$promise,
            vm.platformWechatSetting.$promise]).then(function () {
            vm.regionTemps = RegionTemp.getAllByCondition({items: [{key: "storeId", value: vm.store.id, op: "="}]});

            vm.token = vm.tokens.token;

            $scope.domain = 'http://' + vm.domains.domains[0] + '/';

            if (vm.product.productType2 == 'BOOK') {
                vm.editorTypes = [{name:'相册编辑器',value:'NEW_EDITOR'}];
            }
            if (vm.product.productType2 == 'CALENDAR') {
                vm.editorTypes = [{name:'相册编辑器',value:'NEW_EDITOR'}];
            }
            if (vm.product.productType2 == 'PHOTO') {
                vm.editorTypes = [ {name:'冲印编辑器',value:'PICTURE'},{name:'卡片编辑器',value:'PICTURE_PRO'}];
            }
            if (vm.product.productType2 == 'THING') {
                vm.editorTypes = [{name:'万物编辑器',value:'POSTER'}, {name:'极简编辑器',value:'SIMPLEST'},
                {name:'模块化编辑器',value:'DIY_EDITOR'}, {name:'表单编辑器',value:'FORM_EDITOR'}];
            }

            if (vm.product.itemValues && vm.product.itemValues.length>0) {
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var itemValue = vm.product.itemValues[i];
                    if (itemValue.cancelled) {
                        continue;
                    }
                    if (itemValue.productXmlLines && itemValue.productXmlLines.length > 0) {
                        itemValue.productXmlLines.sort(function(a, b){
                            return a.seq - b.seq;
                        })
                    }
                }
            }

            if (vm.product.objectProperties != null) {
                vm.product.defaultObjectProperties = [];
                vm.product.expandedObjectProperties = [];
                if (vm.product.itemValues && vm.product.itemValues[0] && vm.product.itemValues[0].itemValueLines
                    && vm.product.itemValues[0].itemValueLines[0]){
                    vm.itemValueObjectValues = vm.product.itemValues[0].itemValueLines[0].itemValueObjectValues;
                    $scope.colspanLen = vm.itemValueObjectValues? vm.itemValueObjectValues.length + 5: 5 ;
                }
                for (var i = vm.product.objectProperties.length - 1; i >= 0; i--) {
                    var ob = vm.product.objectProperties[i];
                    if (ob.propertyType == "Default") {
                        vm.product.defaultObjectProperties.push(ob);
                    } else if (ob.propertyType == "Expanded") {
                        vm.product.expandedObjectProperties.push(ob);
                    }
                }
            }
            // $scope.richText = $sce.trustAsHtml(vm.product.richText)
            messageEditor();
        }).then(function () {
        });

        function saleableCheck(saleable) {
            vm.product.saleable = saleable;
        }

        function pTicketCheck(b) {
            vm.product.pTicket = b;
        }

        $scope.removeFile = function (field) {
            if (field == 'coverImg') {
                vm.product.coverImg = null;
            }
            if (field == 'coverImg2') {
                vm.product.coverImg2 = null;
            }
            if (field == 'detailImg') {
                vm.product.detailImg = null;
            }
            if (field == 'webDetailImg') {
                vm.product.webDetailImg = null;
            }
        };

        $scope.removeFile2 = function (itemValue) {
            itemValue.coverImg = null;
        };


        //标准印品文件移除
        $scope.removeFile3 = function (line) {
            line.fileIdentifier = null;
        };

        window.xmlLineProductFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get()+ ".pdf");
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                       for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.productXmlLines && item.productXmlLines.length>0) {
                                for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                                    var line = item.productXmlLines[j];
                                    if (line.uuid == uuid) {
                                        line.fileIdentifier = $scope.domain + data.key;
                                        break;
                                    }
                                }
                            }

                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        window.xmlLineImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                       for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.productXmlLines && item.productXmlLines.length>0) {
                                for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                                    var line = item.productXmlLines[j];
                                    if (line.uuid == uuid) {
                                        line.coverImg = $scope.domain + data.key;
                                        break;
                                    }
                                }
                            }

                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        // itemvalue封面图片修改
        window.itemvalueLineImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.uuid == uuid) {
                                item.coverImg = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.uploadFile = function (fileWatched, uuid) {
            $timeout(function () {
                var file = fileWatched;
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.uuid == uuid) {
                                item.coverImg = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        //产品 coverImg更改
        window.updateImgFile1_2 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.coverImg2 = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
        window.updateImgFile1 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.coverImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        //产品 detailImg更改
        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.detailImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
        //产品 detailImg更改
        window.updateImgFile2 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.webDetailImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go('product', null, {reload: 'product'});
        }

        $scope.validate = function () {
            if (vm.product.name == null || vm.product.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            if (vm.product.bokeh && (vm.product.bokehType == null || vm.product.bokehType == '')) {
                MessageService.error("请选择虚化类型");
                return false;
            }
            if (vm.product.productType == 'CustomPrint' &&  !vm.product.editorType  ) {
                MessageService.error("请录入编辑器类型");
                return false;
            }
            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var item = vm.product.itemValues[i];
                if (item.cancelled || item.deleted) {
                    continue;
                }
                if (item.price == null || item.price === "" || item.price > 1000000 || item.price < 0) {
                    MessageService.error("请输入有效价格");
                    return false;
                }
            }
            return true;
        };


        function save() {
            if (!$scope.validate()) {
                return;
            }
            if (vm.product.bokeh == null || vm.product.bokeh == false) {
                vm.product.bokehType = "Transparent";
            }
            if (vm.product.productType == "Simple") {
                vm.product.objectProperties = [];
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var item = vm.product.itemValues[i];
                    item.objectvalues = [];
                }
            }

            // 提交数据删除itemValue中的模版列表
            var post = angular.copy(vm.product);
            for (var i = 0; i < post.itemValues.length; i++) {
                var itemValue = post.itemValues[i];
                if (itemValue && itemValue.productXmls) {
                    delete itemValue.productXmls;
                }
            }
            post.periodOfValidity = DateUtils.convertLocalDateToServer(post.periodOfValidity);
            vm.isSaving = true;
            if (vm.product.id !== null) {
                Product.update(post, onSaveSuccess, onSaveError);
            } else {
                Product.save(post, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:productUpdate', result);
            $state.go('product', null, {reload: 'product'});
            MessageService.success("保存成功");
            vm.isSaving = false;
        }

        function onSaveError(result) {
            vm.isSaving = false;
            MessageService.error(result.data.message|| "保存失败");
        }

        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image-dialog").removeClass('hide1');
            $(".dialog-form").addClass('opacity-zero');
            $(".origin-image-dialog img").attr('src', pic);
            $(".modal-content,.modal-dialog").addClass('clear-model');
        }

        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image-dialog").addClass('hide1');
            $(".dialog-form").removeClass('opacity-zero');
            $(".modal-content,.modal-dialog").removeClass('clear-model');
            $('body').css("overflow", "auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStartDialog = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOnDialog, true);
            document.addEventListener("mouseup", moveImgEndDialog, true);
        }

        //move事件
        function moveImgOnDialog(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEndDialog() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOnDialog, true);
            document.removeEventListener("mouseup", moveImgEndDialog, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
    }
})();
